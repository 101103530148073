import React, { Component } from "react";

export class Description extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="flex-container">
          <div className="description">
            <div className="desc-container container">
              <div data-aos="fade-up-left" className="first-textbox">
                <p className="text-style-2">{this.props.about.story_p1}</p>
              </div>
              <div data-aos="fade-up-left" className="second-textbox">
                <p className="text-style-2">{this.props.about.story_p2}</p>
              </div>
              <div data-aos="fade-up-left" className="third-textbox">
                <p className="text-style-2">{this.props.about.story_p3}</p>
              </div>
              <div data-aos="fade-up-left" className="fourth-textbox">
                <p className="text-style-2">{this.props.about.story_p4}</p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Description;
