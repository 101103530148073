import React, { Component } from "react";
import $ from "jquery";
import { Carousel } from "react-bootstrap";
import Footer from "../Footer";
import { db } from "../../firebase/firebaseConfig";

export class visionCarousel extends Component {
  state = {
    testimonials: [],
  };
  componentDidMount() {
    this.getTestimonialsData();
  }
  getTestimonialsData = () => {
    db.collection("testimonials")
      .get()
      .then((snapshot) => {
        const testimonials = [];
        snapshot.forEach((doc) => {
          const data = doc.data();

          testimonials.push(data);
        });
        this.setState({ testimonials });
        $(".carousel-item", ".show-other")
          .each(function () {
            var next = $(this).next();
            if (!next.length) {
              next = $(this).siblings(":first");
            }
            next.children(":first-child").clone().appendTo($(this));
          })
          .each(function () {
            var prev = $(this).prev();
            if (!prev.length) {
              prev = $(this).siblings(":last");
            }
            prev.children(":nth-last-child(2)").clone().prependTo($(this));
          });
      })

      .catch((error) => console.log(error));
  };

  loadCarousel() {
    let { testimonials } = this.state;
    if (testimonials.length > 0) {
      return (
        <React.Fragment>
          <Carousel
            indicators={false}
            interval={false}
            controls={false}
            id="carouselExampleCaptions"
            className="carousel slide show-other"
          >
            {testimonials.map((testimonial, index) => (
              <Carousel.Item key={index}>
                <div className="item__third">
                  <div className="vision-container1">
                    <img
                      alt=""
                      src={testimonial.image1}
                      className="image-view"
                    ></img>
                    <div className="vision-content">
                      <div className="testimonial">
                        <div className="testimonial-square"></div>
                        <div className="testimonial-name">Testimonials</div>
                      </div>
                      <div className="vision-comment">
                        <p className="vision-typo">{testimonial.quote}</p>
                      </div>
                      <div>
                        <p className="vision-typo2">{testimonial.name}</p>
                        <p className="vision-typo2">
                          {testimonial.position} of {testimonial.company}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
          <a
            className="carousel-control-prev"
            href="#carouselExampleCaptions"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleCaptions"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </React.Fragment>
      );
    } else {
      return <div></div>;
    }
  }
  render() {
    return (
      <div
        data-aos="fade-up"
        data-aos-delay="1000"
        className="main_container"
        id="vision-carousel"
      >
        <div className="main_container-overlay"></div>
        <div className="main_div section-vision">{this.loadCarousel()}</div>

        {/* <div className="second_div">
    <div className="footer-overlay"></div>
  </div> */}
        <Footer background={{ background: "transparent" }} />
      </div>
    );
  }
}

export default visionCarousel;
