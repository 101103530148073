import React, { Component } from "react";
import $ from "jquery";
import { db } from "../../firebase/firebaseConfig";

export class Header extends Component {
  state = {
    story: "",
  };
  myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }

  componentDidMount() {
    this.getAboutData();

    $(document).on("click", "a", function () {
      $("a").removeClass("actives");
      $(this).addClass("actives");
    });
    // AOS.init();
  }
  getAboutData = () => {
    db.collection("about")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          this.setState({ story: data.story });
        });
        // console.log(projects);
      })

      .catch((error) => console.log(error));
  };
  render() {
    return (
      <div>
        {/* header */}
        <header className="masthead">
          <div className="container1">
            <div className="container1-overlay" />
            {/* <div className="navigation-lg"></div> */}
            {/* <div className="container-mission"> */}
            <div className="sidenav" data-aos="fade-down">
              <a className="active-link" href="about">
                Our Story
              </a>
              <a href="ceo-message">CEO message</a>
              {/* <a href="team">The Team</a> */}
            </div>
            <div className="intro-text">
              <div
                data-aos="fade-down"
                data-aos-delay="400"
                className="OUR-STORY"
              >
                OUR STORY
              </div>
              <div
                data-aos="fade-up"
                data-aos-delay="800"
                className="About_Description"
              >
                {this.state.story}
                {/* Palm Construction is one of the leading Regional Engineering &
                Construction Contractors that takes pride in delivering high,
                complex, Construction Projects. As a firm, we are proud to be
                working in various different sectors, like, Industrial,
                Military, Residential, Administrative & Commercial Buildings,
                Medical & Infrastructure Projects, etc. */}
              </div>
            </div>
          </div>

          {/* </div> */}
        </header>
      </div>
    );
  }
}

export default Header;
