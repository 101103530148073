import React, { Component } from "react";
import Header from "../About/Header";
import Description from "./Description";
import Mission from "./Mission";
import Vision from "./Vision";
import Carousel from "./visionCarousel";
import path from "../../assets/img/path.svg";
import { db } from "../../../src/firebase/firebaseConfig";
export class About extends Component {
  state = {
    about: [],
  };

  componentDidMount() {
    this.fetchAbout();
  }
  fetchAbout() {
    let about = [];
    db.collection("about")

      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.ref.id;
          about.push(data);
        });
        about = about[0];
        this.setState({ about: about });
      })

      .catch((error) => console.log(error));
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <Description about={this.state.about} />
        <Mission about={this.state.about}></Mission>
        <Vision about={this.state.about} />
        <Carousel></Carousel>
        <div className="path-div">
          <img src={path} alt="" />
        </div>
      </React.Fragment>
    );
  }
}

export default About;
