import React from "react";
const Header = () => {
  return (
    <React.Fragment>
      <header className="header">
        <div className="header__text-box">
          <h1 className="heading-primary">
            <span className="heading-primary--sub u-margin-bottom-small">
              Our Passion for Excellence
            </span>
            <span className="heading-primary--main"> Redefines what you </span>
            <span className="heading-primary--main">
              have come to expect from
            </span>
            <span className="heading-primary--main">
              a Construction Company.
            </span>
          </h1>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
