import React from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";

const Clients = (clients) => {
  return (
    <React.Fragment>
      <section className="section-clients">
        <div className="section-clients-overlay"></div>
        <div className="section-clients-header">
          <h3 data-aos="fade-up" className="section-header-sub">
            Our Clients &#9473;&#9473;&#9473;
          </h3>
          <h3 data-aos="fade-up" className="section-header ">
            They Trust Us
          </h3>
        </div>
        <div
          data-aos="fade-up"
          className="container-fluid section-clients-container"
        >
          <Carousel interval={false}>
            {clients.clients &&
              clients.clients.map((parititon, idx) => {
                return (
                  <Carousel.Item key={idx}>
                    <Container
                      className={["u-margin-bottom-medium", "u-center-text"]}
                    >
                      <div className="container-padding" data-aos="fade-up">
                        <Row>
                          {parititon &&
                            parititon.map((client) => {
                              return (
                                <Col
                                  key={client.id}
                                  md={4}
                                  sm={6}
                                  xs={6}
                                  className={[
                                    "u-margin-bottom-big",
                                    "u-center-text",
                                  ]}
                                >
                                  <img
                                    src={client.image}
                                    alt="Logo"
                                    className="client__logo"
                                  />
                                </Col>
                              );
                            })}
                        </Row>
                      </div>
                    </Container>
                  </Carousel.Item>
                );
              })}
          </Carousel>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Clients;
