import React, { Component } from "react";
import Crane from "../../assets/img/crane/crane@2x.png";
import Project from "../../assets/img/project/project@2x.png";
// import Video from "../../assets/img/palm constructions annual tracksound_1.mp4";
import { Link } from "react-router-dom";

export default class About extends Component {
  componentDidMount() {
    const script = document.createElement("script");

    script.src = "./js/accordion.js";
    script.async = true;
    document.body.appendChild(script);
  }
  render() {
    return (
      <section className="section-about">
        <div className="section-about-overlay"></div>
        <div className="section-about-content">
          <div className="aboutus u-margin-bottom-extra-big">
            <div className="video">
              <div className="video__content">
                <video className="bg-video__content" autoPlay muted loop>
                  Your browser is not supported!
                </video>
              </div>
              <div className="enable-sound">Enable Sound</div>
              <svg width="80px" height="80px" viewBox="0 0 100 100">
                <circle className="anicircle" cx="50" cy="50" r="40" />
              </svg>
            </div>
          </div>
          <div className="section-about_story">
            <div className="u-margin-bottom-medium animate-scroll">
              <h2 className="heading-secondary" data-aos="fade-up">
                Our Story
              </h2>
            </div>
            <div className="row-grid">
              <div className="u-margin-bottom-medium">
                <p
                  className="paragraph u-padding"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Palm Construction Company "PCC" is a Member of Palm Group and
                  a leading regional engineering and construction contractor
                  specialized turn - key contractor in infrastructure and
                  specialized in delivering high complex Construction Projects
                  in different Sectors such as Industrial, Military,
                  Residential, Administration / Commercial Buildings and
                  Medical.
                </p>
              </div>
              <div className="col-1-of-2 u-padding">
                <Link to="/about" style={{ textDecoration: "none" }}>
                  <button
                    data-aos="fade-up"
                    data-aos-delay="100"
                    className="button button--isi button--isi--dirty-blue button--text-thick button--text-upper button--size-s"
                  >
                    <span>KNOW MORE</span>
                  </button>
                </Link>
              </div>
              <div className="col-1-of-2 services-width">
                <div className="services">
                  <h3 data-aos="fade-up" data-aos-delay="300">
                    Our Services &nbsp; &nbsp; &#9473;&#9473;&#9473;
                  </h3>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="400"
                    className="collapsible-services"
                  >
                    <div className="accordion-div">
                      <div className="accordion">
                        <i>
                          <img className="img-icon" src={Crane} alt="" />
                        </i>
                        <div className="service-name"> Construction </div>
                      </div>
                      <div className="panel">
                        <p>
                          PALM Construction is a leading global Engineering and
                          Construction contractor, primarily focused on High-end
                          Commercial, Residential, Administration, Industrial,
                          Healthcare, Antique Buildings, Landscape,
                          Infrastructure, Electromechanical Projects and Real
                          Estate. Professional Teams that take the company’s
                          core values seriously to accomplish the projects
                          goals.
                        </p>
                      </div>
                    </div>
                    <div className="accordion-div">
                      <div className="accordion">
                        <i>
                          <img className="img-icon" src={Project} alt="" />
                        </i>
                        <div className="service-name"> Project Management </div>
                      </div>
                      <div className="panel">
                        <p>
                          Palm Construction applies a professional service that
                          uses specialized Project Management Techniques to
                          oversee the planning, design, construction and all
                          project’s phases from its beginning to its end, by
                          imposing a professional control on project›s time,
                          cost and quality, which is compatible with all project
                          delivery systems.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
