import React, { Component } from "react";
import Footer from "../Footer";
import { Dashboard, Rectangle } from "../../assets/img";
import { Link } from "react-router-dom";
import Filter from "./filter";
import { db } from "../../../src/firebase/firebaseConfig";
import { withRouter } from "react-router-dom";
import AOS from "aos";
import { ceil } from "lodash";

var loadjs = require("loadjs");

function returnStyle(image) {
  return {
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75)), ${image}`,
  };
}
class ProjectsGrid extends Component {
  state = {
    projects: [],
    singleProjects: [],
    divCount: 0,
    itemsCount: 0,
    loading: false,
    status: "loading projects",
    projectsIdx: 0,
  };

  componentDidMount = () => {
    loadjs("./js/projects-grid.js", function () {});
    this.getProjectData();
    AOS.init({ duration: 1500 });
    window.scrollTo(0, 0);
    // this.fetchProjects();
    this.setState({
      loading: true,
    });
  };

  prevPage() {
    let { projectsIdx, projects } = this.state;
    projectsIdx--;
    if (projectsIdx <= -1) {
      projectsIdx = projects.length - 1;
    }
    document.getElementById("projects-grid-top").scrollIntoView({
      behavior: "smooth",
    });
    this.setState({ projectsIdx });
  }
  nextPage() {
    let { projectsIdx, projects } = this.state;
    projectsIdx++;
    if (projectsIdx >= projects.length) {
      projectsIdx = 0;
    }

    document.getElementById("projects-grid-top").scrollIntoView({
      behavior: "smooth",
    });
    this.setState({ projectsIdx });
  }
  // this.setState({ project: nextProject });
  // t
  getProjectData = () => {
    db.collection("projects")
      .get()
      .then((snapshot) => {
        const projects = [];
        snapshot.forEach((doc) => {
          const data = doc.data();

          data["id"] = doc.ref.id;
          projects.push(data);
        });
        if (projects.length < 1) {
          this.setState({ loading: "no projects available" });
        }
        this.setState({ loading: false });
        this.setState({ singleProjects: projects });

        const result = new Array(Math.ceil(projects.length / 7))
          .fill()
          .map((_) => projects.reverse().splice(0, 7));
        this.setState({ projects: result });
        this.setState({ divCount: ceil(projects.length / 7) });
      })
      .catch((error) => console.log(error));
  };
  applyFilter = (filter) => {
    // console.log(filter);

    var query = db.collection("projects");
    if (filter["types"] && filter["types"].length > 0) {
      query = query.where("title", "in", filter["types"]);
    }
    query
      .where("price", "<", filter["rightValue"])
      .where("price", ">", filter["leftValue"])
      .get()
      .then((snapshot) => {
        const projects = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          this.setState({ docID: doc.id });
          if (
            data["date"] >= filter["startDate"] &&
            data["date"] <= filter["endDate"]
          ) {
            data["id"] = doc.ref.id;
            projects.push(data);
          }
        });

        const result = new Array(Math.ceil(projects.length / 7))
          .fill()
          .map((_) => projects.reverse().splice(0, 7));
        this.setState({ projects: result });
        if (this.state.projects.length < 1) {
          this.setState({ status: "No projects with this type" });
        }
        this.setState({ divCount: ceil(projects.length / 7) });
        this.forceUpdate();
        // console.log(this.state.projects);
      })
      .catch((error) => console.log(error));
  };

  navigate(id, projects, index) {
    this.props.history.push(`/projects/${id}`, {
      id,
      projects,
      index,
    });
  }

  renderProjects() {
    let projectsList = this.state.projects[this.state.projectsIdx];
    // var projectsList =
    var i = this.state.projectsIdx;
    if (projectsList) {
      if (projectsList.length === 7) {
        return (
          <div key={this.state.projectsIdx} className="projects-container">
            <div className="container-fluid projects-div">
              {projectsList.map((project, index) => {
                // if (index + 1 == 7) {
                //   var array = myprojects;

                //   array.splice(0, 7);
                //   myprojects = array;
                // }
                let display_image = "";
                if (
                  project.display_images != null &&
                  project.display_images.length > 0
                )
                  if (Array.isArray(project.display_images)) {
                    display_image = project.display_images[0].url;
                  } else {
                    display_image = project.display_images;
                  }
                else {
                  display_image =
                    "https://previews.123rf.com/images/pavelstasevich/pavelstasevich1811/pavelstasevich181101027/112815900-no-image-available-icon-flat-vector.jpg";
                }
                return (
                  // this.state.projects.pop(this.state.itemsCount);
                  <div
                    data-aos="fade-up"
                    key={index + i}
                    className={"item-" + (index + 1)}
                  >
                    <div className="project-card">
                      <div
                        className="project-div"
                        style={returnStyle(`url('${display_image}')`)}
                      >
                        <div className="project-data">
                          <div className="project-name">
                            <div className="type">{project.title}</div>
                            <div className="name">{project.client}</div>
                          </div>
                          <div
                            className="svg"
                            onClick={() =>
                              this.navigate(
                                project.id,
                                this.state.singleProjects,
                                index
                              )
                            }
                          >
                            <div className="circle-button"></div>
                            <svg>
                              <circle
                                className="anicircle"
                                cx="35"
                                cy="35"
                                r="30"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      } else {
        return (
          <div
            key={i}
            className="projects-container container projects-filtered"
          >
            <div className="row projects-div">
              {projectsList.map((project, index) => {
                // if (index + 1 == 7) {
                //   var array = myprojects;

                //   array.splice(0, 7);
                //   myprojects = array;
                // }
                let display_image = "";
                if (
                  project.display_images != null &&
                  project.display_images.length > 0
                )
                  if (Array.isArray(project.display_images)) {
                    display_image = project.display_images[0].url;
                  } else {
                    display_image = project.display_images;
                  }
                else {
                  display_image =
                    "https://previews.123rf.com/images/pavelstasevich/pavelstasevich1811/pavelstasevich181101027/112815900-no-image-available-icon-flat-vector.jpg";
                }
                return (
                  // this.state.projects.pop(this.state.itemsCount);
                  <div
                    data-aos="fade-up"
                    key={index + i}
                    className={"col-md-6"}
                  >
                    <div className="project-card">
                      <div
                        className="project-div"
                        style={returnStyle(`url('${display_image}')`)}
                      >
                        <div className="project-data">
                          <div className="project-name">
                            <div className="type">{project.title}</div>
                            <div className="name">{project.client}</div>
                          </div>
                          <div
                            className="svg"
                            onClick={() =>
                              this.navigate(
                                project.id,
                                this.state.singleProjects,
                                index
                              )
                            }
                          >
                            <div className="circle-button"></div>
                            <svg>
                              <circle
                                className="anicircle"
                                cx="35"
                                cy="35"
                                r="30"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      }
    }
  }

  render() {
    // var projectsList = this.state.projects[this.state.projectsIdx];
    // var i = this.state.projectsIdx;
    return (
      <div className="projects-page">
        <React.Fragment>
          <div className="projects-grid-new">
            {this.state.loading && (
              <div className="spinner-grow spinner text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
            <section id="projects-grid-top" className="section-projects">
              <div className="section-projects-overlay"></div>
              {this.state.projects.length < 1 && (
                <div className="no-projects">{this.state.status}</div>
              )}
              {/* {Array.from(Array(this.state.divCount), (e, i) => {
                return (
                  <div key={i} className="projects-container">
                    <div className="container-fluid projects-div">
                      {myprojects.length > 0 &&
                        myprojects.slice(0, 7).map((project, index) => {
                          if (index + 1 == 7) {
                            var array = myprojects;
                            array.splice(0, 7);
                            myprojects = array;
                          }
                          let display_image = "";
                          if (
                            project.display_images != null &&
                            project.display_images.length > 0
                          )
                            if (Array.isArray(project.display_images)) {
                              display_image = project.display_images[0].url;
                            } else {
                              display_image = project.display_images;
                            }
                          else {
                            display_image = project.images[0].url;
                          }
                          return (
                            // this.state.projects.pop(this.state.itemsCount);
                            <div
                              data-aos="fade-up"
                              key={index + i}
                              className={"item-" + (index + 1)}
                            >
                              <div className="project-card">
                                <div
                                  className="project-div"
                                  style={returnStyle(`url('${display_image}')`)}
                                >
                                  <div className="project-data">
                                    <div className="project-name">
                                      <div className="type">
                                        {project.title}
                                      </div>
                                      <div className="name">
                                        {project.client}
                                      </div>
                                    </div>
                                    <div
                                      className="svg"
                                      onClick={() =>
                                        this.navigate(
                                          project.id,
                                          this.state.projects,
                                          index
                                        )
                                      }
                                    >
                                      <div className="circle-button"></div>
                                      <svg>
                                        <circle
                                          className="anicircle"
                                          cx="35"
                                          cy="35"
                                          r="30"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
               */}

              {this.renderProjects()}

              <div className="buttons-div">
                <button
                  className="prev-a button button--isi button--isi--white button--text-thick button--text-upper "
                  onClick={() => this.prevPage()}
                >
                  <div className="theimage" />

                  <div>Previous Page</div>
                </button>
                <button
                  className="next-a button button--isi button--isi--dirty-blue button--text-thick button--text-upper"
                  onClick={() => this.nextPage()}
                >
                  <div className="theimage" />

                  <div>Next Page</div>
                </button>
              </div>
            </section>
          </div>
          <div className="projects-grid">
            <section className="section-footer">
              <div className="section-footer-overlay"></div>
              <Footer background={{ background: "transparent" }} />
            </section>
            <div
              id="filterBackground"
              className="filter-background"
              onClick={window.toggleFilterWindow}
            ></div>
            <Filter applyFilter={this.applyFilter.bind(this)}></Filter>
            <div
              id="filterDiv"
              className="filter-div"
              style={{ display: window.innerWidth > 900 ? "block" : "none" }}
            >
              <div className="tiles">
                <div className="tile-type">
                  <Link to="/projects-tile">
                    <span>
                      <img className="tile-type-icon" src={Rectangle} alt="" />
                    </span>
                    <span>Tile View</span>
                  </Link>
                </div>
                <div className="tile-type">
                  <span>
                    <img className="tile-type-icon" src={Dashboard} alt="" />
                  </span>
                  <span className="tile-type-active">Grid View</span>
                </div>
              </div>
            </div>
            <div
              id="filterButton"
              className="filter-button open-filter"
              onClick={window.toggleFilterWindow}
            ></div>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default withRouter(ProjectsGrid);
