import React, { Component } from "react";
import AOS from "aos";
import Footer from "../Footer";
import { db } from "../../../src/firebase/firebaseConfig";
var loadjs = require("loadjs");
export class partners extends Component {
  isLoading = true;
  componentDidMount() {
    this.getPartners();
    AOS.init({ duration: 1500 });

    // loadjs("./js/partners.js", function () {});
  }
  navigate = () => {
    this.props.history.push("/projects-grid");
  };
  state = {
    partners: [],
  };

  getPartners() {
    db.collection("partners")
      .get()
      .then((snapshot) => {
        const partners = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          partners.push(data);
        });
        this.setState({ partners: partners });
        console.log(this.state.partners);
        loadjs("./js/partners.js", function () {});
        this.isLoading = false;
      });
  }
  render() {
    return (
      <div className="partners-main">
        <div className="partners-header">
          <div className="partners-header-overlay"></div>
          <div className="partners-container">
            <div data-aos="fade-up" className="partners-title">
              Our Partners
            </div>
            <div data-aos="fade-down" className="partners-collection">
              {this.state.partners &&
                this.state.partners.map((partner) => {
                  return (
                    <div className="normal-margins">
                      <img src={partner.image} alt=""></img>
                      <button
                        onClick={this.navigate}
                        className="button button--isi button--isi--dirty-blue button--text-thick button--text-upper button--size-s"
                      >
                        View Partner
                      </button>
                    </div>
                  );
                })}
            </div>
            {this.isLoading ? (
              <button className="button button--isi button--isi--white button--text-thick button--text-upper  button-load">
                <div>Load more</div>
              </button>
            ) : null}
          </div>
        </div>
        <Footer> </Footer>
      </div>
    );
  }
}

export default partners;
