// import firebase from "firebase/app"; // doing import firebase from 'firebase' or import * as firebase from firebase is not good practice.
// import "firebase/auth";
// import "firebase/database";
// import "firebase/firestore";
import * as firebase from "firebase";
import Axios from "axios";

export let config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: "palm-construction",
  storageBucket: "palm-construction.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

export const fireapp = firebase.initializeApp(config);

const db = firebase.firestore();

export { Axios, db };
