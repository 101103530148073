import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AOS from "aos";

var loadjs = require("loadjs");
export class article extends Component {
  componentDidMount() {
    loadjs("./js/news.js", function () {});
    AOS.init({ duration: 1500 });
  }

  Detailspage({ id, author, image, articles, index }) {
    this.props.history.push(`/news/${id}`, {
      name: id,
      author,
      image,
      articles,
      index,
    });
  }
  render() {
    return (
      <div data-aos="fade-up" className="single-article">
        <div className="article-title">{this.props.title}</div>
        <div className="article-secondary">{this.props.author}</div>
        <div className="article-image">
          <div className="featured-post">Featured post</div>
          <div className="article-date">
            <span>MAY</span>
            <label>8</label>
          </div>

          <img src={this.props.image} alt=""></img>
        </div>
        <div
          className="article-description"
          dangerouslySetInnerHTML={{
            __html: `${this.props.description}`,
          }}
        ></div>
        <button
          onClick={() => this.Detailspage(this.props)}
          className="button button--isi button--isi--dirty-blue button--text-thick button--text-upper button--size-s"
        >
          Read article
        </button>
      </div>
    );
  }
}

export default withRouter(article);
