import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import navyButton from "../assets/img/singleProject/back-navy.png";
import Footer from "./Footer";
var loadjs = require("loadjs");

class Navbar extends Component {
  componentDidMount() {
    loadjs("./js/mobile-navbar.js", function () {});
  }
  renderFooter(path) {
    if (this.props.location.pathname !== path) {
      return <Footer></Footer>;
    }
  }
  returnClass(paths) {
    const active = (path) => {
      if (path === "/") {
        return path === this.props.location.pathname;
      }

      return this.props.location.pathname.includes(path) === true;
    };
    return paths.some(active) ? "menu__item--current menu__item" : "menu__item";
  }
  render() {
    return (
      <React.Fragment>
        <Link
          to="/"
          id="header_logo"
          className="header__logo-box"
          style={{ display: "block" }}
        >
          {/* <img src={Logo} alt="Logo" className="header__logo" /> */}
        </Link>
        <div className="navigation-lg">
          <div id="navigationRow" className="first_row">
            <div>
              <ul>
                <li>
                  <a href="/careers">Careers</a>
                </li>
                <li>
                  <a
                    style={{ paddingRight: "0px" }}
                    id="dropdown"
                    onClick={window.toggleDropDown}
                  >
                    Company Websites
                    <i id="dropdown-arrow" className="fas fa-chevron-down"></i>
                  </a>
                  <input
                    type="checkbox"
                    className="menu_arrow menu_arrow-invert"
                    name="menu_arrow"
                    id="menu_arrow"
                    onChange={window.handleChange}
                  />
                  <label
                    htmlFor="menu_arrow"
                    style={{ display: "none" }}
                  ></label>
                  <div id="companyWebsites" className="menu_options">
                    <div className="menu_options-header">
                      Palm Holding Websites
                    </div>
                    <ul>
                      <div className="menu_options-websites">
                        <li className="menu_options-webistes--list">
                          <a href="#" className="website u-disable">
                            All Spatial
                          </a>
                        </li>
                        <li className="menu_options-webistes--list">
                          <a href="#" className="website u-disable">
                            Digital Spark
                          </a>
                        </li>
                        <li className="menu_options-webistes--list">
                          <a href="#" className="website u-marine u-bold-600">
                            Palm Construction
                          </a>
                        </li>
                        <li className="menu_options-webistes--list">
                          <a href="#" className="website u-disable">
                            Smart Ventures
                          </a>
                        </li>
                        <li className="menu_options-webistes--list">
                          <a href="#" className="website u-disable">
                            Al Rowad Import & Export
                          </a>
                        </li>
                      </div>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="second_row menu--shylock">
            <ul className="menu__list">
              <li className={this.returnClass(["/"])}>
                <a href="/" className="menu__link">
                  Home
                </a>
              </li>
              <li
                className={this.returnClass([
                  "/about",
                  "/team",
                  "/ceo-message",
                ])}
              >
                <a className="menu__link" href="/about">
                  About
                </a>
              </li>
              <li className={this.returnClass(["project"])}>
                <a href="/projects-tile" className="menu__link">
                  Projects
                </a>
              </li>
              <li className={this.returnClass(["/partners"])}>
                <a className="menu__link" href="/partners">
                  Partners
                </a>
              </li>
              <li className={this.returnClass(["/news"])}>
                <a className="menu__link" href="/news">
                  News
                </a>
              </li>
              <li>
                <a href="/contactus" className="contact">
                  <button
                    id="contact_button"
                    className="button--isi button--isi--marine u-uppercase"
                  >
                    Contact
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="navigation">
          <input
            type="checkbox"
            className="navigation__checkbox"
            id="navi-toggle"
            onChange={window.openNavBar}
          />
          <label
            htmlFor="navi-toggle"
            className="navigation__button"
            id="nav_button"
          >
            <span className="navigation__icon"> &nbsp; </span>
          </label>
          <div className="navigation__background"> &nbsp; </div>
          <nav className="navigation__nav">
            <ul id="navbar-list" className="navigation__list">
              <li className="navigation__item">
                <a
                  href="/"
                  className="navigation__link"
                  onClick={window.navigate}
                >
                  Home
                </a>
              </li>
              <li className="navigation__item" onClick={window.navigate}>
                <a href="/about" className="navigation__link">
                  About
                </a>
              </li>
              <li className="navigation__item" onClick={window.navigate}>
                <a href="/projects-tile" className="navigation__link">
                  Projects
                </a>
              </li>
              <li className="navigation__item" onClick={window.navigate}>
                <a href="/partners" className="navigation__link">
                  partners
                </a>
              </li>
              <li
                className="navigation__item add-border"
                onClick={window.navigate}
              >
                <a href="/news" className="navigation__link">
                  news
                </a>
              </li>
              <li style={{ marginTop: "3rem" }}>
                <a
                  href="#"
                  className="navigation__link-2"
                  onClick={window.toggleMenu}
                >
                  Company Websites
                </a>
              </li>
              <li>
                <a href="/careers" className="navigation__link-2">
                  Careers
                </a>
              </li>
              {/* <li>
                <a href="#" className="navigation__link-2">
                  CSR
                </a>
              </li> */}
            </ul>

            <ul
              id="websites-list"
              className="navigation__list"
              style={{ display: "none" }}
            >
              <a onClick={window.toggleMenu} className="menuBack">
                <img alt="" src={navyButton} />
                <div>Back to Menu</div>
              </a>
              {/* <a
                href="#"
                onClick={window.toggleMenu}
                style={{ textDecoration: "none" }}
              >
                <span className="back-menu"> &#60; Back to Menu </span>
              </a> */}
              <li className="navigation__item add-border">
                <a
                  href="#"
                  className="navigation__link palm-name u-no-transform u-bold-600"
                >
                  Palm Holding Websites
                </a>
              </li>
              <li style={{ marginTop: "3rem" }}>
                <a href="#" className="navigation__link-2">
                  All Spatial
                </a>
              </li>
              <li>
                <a href="#" className="navigation__link-2">
                  Digital Spark
                </a>
              </li>
              <li>
                <a href="#" className="navigation__link-2 u-marine">
                  Palm Construction
                </a>
              </li>
              <li>
                <a href="#" className="navigation__link-2">
                  Smart Ventures
                </a>
              </li>
              <li>
                <a href="#" className="navigation__link-2">
                  Al Rowad Import & Export
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Navbar);
