import React, { Component } from "react";
import Footer from "../Footer";
import { db } from "../../../src/firebase/firebaseConfig";
import { Rectangle_black, Dashboard_grey } from "../../assets/img";
import { Link } from "react-router-dom";
import Filter from "./filter";
import Sample from "./sampleProject";
import $ from "jquery";

class ProjectsTile extends Component {
  prev;
  state = {
    projects: [],
    docID: "",
    index: 0,
  };

  componentDidMount() {
    this.prev = $(".projects-page").scrollTop();
    this.getProjectData();
  }

  handleScroll = (e) => {
    let index = this.state.index + 1;
    if (index === this.state.projects.length) index = 0;
    this.setState({ index });
  };
  changeProject(tile) {
    let { index, projects } = this.state;
    switch (tile) {
      case "nav-tile-press-2":
        --index;
        if (index < 0) {
          index = projects.length - 1;
        }
        this.setState({ index });
        break;
      case "nav-tile-press-1":
        --index;
        if (index < 0) {
          index = projects.length - 1;
        }
        --index;
        if (index < 0) {
          index = projects.length - 1;
        }
        this.setState({ index });
        break;
      case "nav-tile-press-4":
        ++index;
        if (index === projects.length) {
          index = 0;
        }
        this.setState({ index });
        break;
      case "nav-tile-press-5":
        ++index;
        if (index === projects.length) {
          index = 0;
        }
        ++index;
        if (index === projects.length) {
          index = 0;
        }
        this.setState({ index });
        break;
      default:
        break;
    }
    // console.log(index);
  }
  applyFilter = (filter) => {
    // console.log(filter);

    var query = db.collection("projects");
    if (filter["types"] && filter["types"].length > 0) {
      query = query.where("title", "in", filter["types"]);
    }

    query
      .where("price", "<", filter["rightValue"])
      .where("price", ">", filter["leftValue"])
      // .orderByChild("date")
      // .startAt("30-5-2019")
      // .endAt("31-5-2019")
      // .where("location", "==", filter["location"])
      .get()
      .then((snapshot) => {
        // console.log("innn");
        const projects = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          this.setState({ docID: doc.id });
          if (
            data["date"] >= filter["startDate"] &&
            data["date"] <= filter["endDate"]
          ) {
            data["id"] = doc.ref.id;
            projects.push(data);
          }
        });
        this.setState({ projects: projects });
        // console.log(this.state.projects);
      })
      .catch((error) => console.log(error));
  };

  getProjectData = () => {
    db.collection("projects")

      .get()
      .then((snapshot) => {
        const projects = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.ref.id;
          projects.push(data);
        });
        this.setState({ projects: projects });
        // console.log(projects);
        setInterval(() => {
          let nav_tile_1 = $(`.nav-tile-visible-1`);
          let nav_tile_2 = $(`.nav-tile-visible-2`);
          let nav_tile_3 = $(`.nav-tile-visible-3`);
          let nav_tile_4 = $(`.nav-tile-visible-4`);
          let nav_tile_5 = $(`.nav-tile-visible-5`);
          nav_tile_1.attr("class", `nav-tile-visible-5`);
          nav_tile_2.attr("class", `nav-tile-visible-1`);
          nav_tile_3.attr("class", `nav-tile-visible-2`);
          nav_tile_4.attr("class", `nav-tile-visible-3`);
          nav_tile_5.attr("class", `nav-tile-visible-4`);

          let tileData = $(`.flip-card-inner`);
          tileData.attr("class", `flip-card-inner moveOut`);
          // let viewButton = $(`.viewProjectButton`);
          // setTimeout(() => {
          //   viewButton.css("visibility", "hidden");
          // }, 300);
          setTimeout(() => {
            tileData.removeClass("moveOut");
            this.changeProject("nav-tile-press-4");
            tileData.attr("class", `flip-card-inner moveIn`);
          }, 750);
          setTimeout(() => {
            tileData.removeClass("moveIn");
          }, 1800);
        }, 5000);
      })

      .catch((error) => console.log(error));
  };

  renderSample(project) {
    if (project) {
      let display_image = "";
      if (project.display_images != null && project.display_images.length > 0)
        if (Array.isArray(project.display_images)) {
          display_image = project.display_images[0].url;
        } else {
          display_image = project.display_images;
        }
      else {
        // display_image = project.images[0].url;
        display_image =
          "https://previews.123rf.com/images/pavelstasevich/pavelstasevich1811/pavelstasevich181101027/112815900-no-image-available-icon-flat-vector.jpg";
      }
      return (
        <Sample
          key={project.id}
          title={project.title}
          type={project.client}
          id={project.id}
          images={display_image}
          projects={this.state.projects}
          index={this.state.index}
        ></Sample>
      );
    }
    return <div></div>;
  }
  render() {
    let project = this.state.projects[this.state.index];
    return (
      <div className="projects-page">
        <React.Fragment>
          <div className="projects-tile">
            <section className="section-projects">
              <div className="section-projects-overlay"></div>
              {/* <div className="section-projects-header">
            <h3 className="section-header-projects ">our latest projects</h3>
          </div> */}

              <div>
                <div className="tile-div">
                  <div className="nav-tile-visible-1"></div>
                  <div className="nav-tile-visible-2"></div>
                  <div className="nav-tile-visible-3"></div>
                  <div className="nav-tile-visible-4"></div>
                  <div className="nav-tile-visible-5"></div>
                  <div className="nav-tile-press-1 nav-tile"></div>
                  <div className="nav-tile-press-2 nav-tile"></div>
                  <div className="nav-tile-press-3 nav-tile"></div>
                  <div className="nav-tile-press-4 nav-tile"></div>
                  <div className="nav-tile-press-5 nav-tile"></div>
                  <div className="flip-card">
                    <div className="flip-card-inner">
                      <div
                        className="tile-div-animate"
                        style={{
                          display: "flex",
                          width: "100%",
                          position: "relative",
                        }}
                      >
                        {/* <div
                      className="tile-background"
                      style={{
                        backgroundColor: "red",
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: "0",
                        left: "0",
                      }}
                    ></div> */}
                        {this.renderSample(project)}
                      </div>
                      {/* <div class="flip-card-back"></div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section-footer">
              {/* <div className="section-footer-overlay"></div> */}
              <Footer />
            </section>
            <div
              id="filterBackground"
              className="filter-background"
              onClick={window.toggleFilterWindow}
            ></div>
            <Filter applyFilter={this.applyFilter.bind(this)}></Filter>
            <div
              id="filterDiv"
              className="filter-div"
              style={{ display: "block" }}
            >
              <div className="tiles">
                <div className="tile-type">
                  <span>
                    <img
                      className="tile-type-icon"
                      src={Rectangle_black}
                      alt=""
                    />
                  </span>
                  <span className="tile-type-active">Tile View</span>
                </div>
                <div className="tile-type">
                  <Link to="/projects-grid">
                    <span>
                      <img
                        className="tile-type-icon"
                        src={Dashboard_grey}
                        alt=""
                      />
                    </span>
                    <span>Grid View</span>
                  </Link>
                </div>
              </div>
            </div>
            <div
              id="filterButton"
              className="filter-button open-filter"
              onClick={window.toggleFilterWindow}
            ></div>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default ProjectsTile;
