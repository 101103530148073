import React, { Component } from "react";
import Footer from "../../Footer";
import path from "../../../assets/img/path.svg";
import AOS from "aos";
import { db } from "../../../firebase/firebaseConfig";
import { MrHesham } from "../../../assets/img";

export class Ceo extends Component {
  state = {
    about: [],
  };
  componentDidMount() {
    this.getAboutData();
  }
  getAboutData = () => {
    let about = [];
    db.collection("about")

      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.ref.id;
          about.push(data);
        });
        about = about[0];
        this.setState({ about: about });
      })

      .catch((error) => console.log(error));
  };
  render() {
    AOS.init({ duration: 1500 });

    return (
      <React.Fragment>
        <div>
          {/* header */}
          <header className="ceo-header">
            <div className="ceo-container">
              <div className="ceo-container-overlay" />
              <div data-aos="fade-up" className="sidenav">
                <a href="about">Our Story</a>
                <a className="active-link" href="ceo-message">
                  CEO message
                </a>
                {/* <a href="team">The Team</a> */}
              </div>

              <div className="CEO-MESSAGE">
                <h2 data-aos="fade-down">MESSAGE</h2>
                <h2 data-aos="fade-up">FROM THE CEO</h2>
              </div>
            </div>
            {/* </div> */}
          </header>
          <div>
            <div className="ceo-description">
              <div className="desc_container">
                <img
                  data-aos="fade-up"
                  className="ceo-image"
                  alt=""
                  style={{ backgroundImage: `url(${MrHesham})` }}
                ></img>
                <div data-aos="fade-up" className="ceo-quote">
                  {this.state.about.ceoMessage}
                </div>
                <div data-aos="fade-up" className="ceo-title">
                  Eng. Hisham Hashish, CEO & Founder of Palm Construction
                </div>
                <div className="history-container">
                  <p data-aos="fade-up" className="ceo-history">
                    {this.state.about.ceo_p1}
                  </p>
                  <p data-aos="fade-up" className="ceo-history">
                    {this.state.about.ceo_p2}
                  </p>
                  <p data-aos="fade-up" className="ceo-history">
                    {this.state.about.ceo_p3}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />

        <div className="path-div">
          <img src={path} alt="" />
        </div>
      </React.Fragment>
    );
  }
}

export default Ceo;
