import React, { Component } from "react";
import navyButton from "../../assets/img/singleProject/back-navy.png";
import up from "../../assets/img/news/up.png";
import { db } from "../../../src/firebase/firebaseConfig";
import { withRouter } from "react-router-dom";

// import blueButton from "../../assets/img/singleProject/back-blue.png";
// import whiteButton from "../../assets/img/singleProject/back-white.png";
import Footer from "../Footer";
import $ from "jquery";
var loadjs = require("loadjs");

export class articleDetails extends Component {
  state = {
    articles: [],
    index: 0,
    article: [],
  };
  componentDidMount() {
    this.getArticleDetails();
    window.scrollTo(0, 0);
  }

  Detailspage({ id }) {
    this.props.history.push(`/news/${id}`, {
      name: id,
    });
    document.getElementById("content").scrollIntoView({
      behavior: "smooth",
    });
  }

  getArticleDetails() {
    var article = "";
    db.collection("articles")
      .doc(this.props.match.params.id)
      .get()
      .then((doc) => {
        const article_data = doc.data();
        // console.log(article_data);
        article = article_data;
        this.setState({ article: article });
        console.log(this.props.location.state);
        if (this.props.location.state["articles"]) {
          this.setState({
            articles: this.props.location.state["articles"],
            index: this.props.location.state["index"],
          });
        } else {
          this.getArticles();
        }
      })
      .catch((error) => console.log(error));
  }

  goBack() {
    this.props.history.push(`/news`);
  }

  getArticles = () => {
    var counter = 0;
    db.collection("articles")
      .get()
      .then((snapshot) => {
        const articles = [];
        snapshot.forEach((doc) => {
          const article_data = doc.data();
          article_data["id"] = doc.ref.id;
          if (article_data["id"] === this.state.article["id"]) {
            this.setState({ index: counter });
          } else {
            counter++;
          }
          articles.push(article_data);
        });
        this.setState({ articles });
        console.log(this.state);
      })
      .catch((error) => console.log(error));
  };
  nextPage() {
    let { index, articles } = this.state;
    console.log(this.state);

    index++;
    if (index >= articles.length) {
      index = 0;
    }
    this.setState({ index });
    let nextArticle = articles[index];
    let { id } = nextArticle;
    this.Detailspage({ id });
    window.scrollTo(0, 0);
    this.setState({ article: nextArticle });
  }
  prevPage() {
    let { index, articles } = this.state;
    index--;
    if (index <= -1) {
      index = articles.length - 1;
    }
    this.setState({ index });
    let nextArticle = articles[index];
    let { id } = nextArticle;
    this.Detailspage({ id });
    window.scrollTo(0, 0);
    this.setState({ article: nextArticle });
  }
  constructor() {
    super();

    loadjs("./js/article.js", function () {
      $("button.up-button").on("click", function () {
        document.getElementById("content").scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }
  render() {
    return (
      <div className="section-article">
        <div className="article-header">
          <div className="article-header-overlay"></div>

          {/* <a name="top"></a> */}
          <div className="article-main">
            <a onClick={() => this.goBack()} className="newsBack">
              <img alt="" src={navyButton} />
              <div>Back to News</div>
            </a>
            {/* <div className="social-logos">
              <div>
                <img alt="" src={twitter}></img>
              </div>
              <div>
                <img alt="" src={facebook}></img>
              </div>
              <div>
                <img alt="" src={linkedin}></img>
              </div>
              <div>
                <img alt="" src={copylink}></img>
              </div>
            </div> */}

            {this.state.article && (
              <div id="content" className="container">
                <div className="content-div">
                  <div className="sub-div">
                    <div className="article-title">
                      {this.state.article.title}
                    </div>
                    <div className="article-secondary">
                      {this.state.article.author}
                    </div>
                  </div>
                  <div className="image-div">
                    <img alt="" src={this.state.article.image}></img>
                  </div>
                  <div className="content">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${this.state.article.body}`,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="second-div">
            <div className="second-div-overlay"></div>
            <div className="control-div">
              <div className="gradient-line-horizontal"></div>
              <div className="buttons-div">
                <button
                  className="prev-a button button--isi button--isi--white button--text-thick button--text-upper button--size-s"
                  onClick={() => this.prevPage()}
                >
                  <div className="theimage" />
                  <div>Previous Article</div>
                </button>
                <button
                  className="next-a button button--isi button--isi--dirty-blue button--text-thick button--text-upper button--size-s"
                  onClick={() => this.nextPage()}
                >
                  <div className="theimage" />
                  <div>Next Article</div>
                </button>
                <button className="up-button ">
                  {/* <a href="#top"></a> */}
                  <img alt="" src={up}></img>
                </button>
              </div>
            </div>
            <Footer background={{ background: "transparent" }} />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(articleDetails);
