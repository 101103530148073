import React, { Component } from "react";
export class Vision extends Component {
  render() {
    return (
      <section className="bg-light vision-section" id="vision">
        <div className="container first_div">
          <div className="row">
            <div className="col-lg-12 text-left">
              <div
                data-aos="fade-up"
                data-aos-delay="1000"
                className="section-heading text-uppercase Vision-title"
              >
                Vision
              </div>
              <div
                data-aos="fade-down"
                data-aos-delay="1000"
                className="section-subheading To-form-long-term-m"
              >
                {this.props.about.vision}
              </div>
            </div>
          </div>
          <div />
        </div>
      </section>
    );
  }
}

export default Vision;
