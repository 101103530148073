import React, { Component } from "react";
import CheckBox from "./filter-checkbox";
import $ from "jquery";
var loadjs = require("loadjs");
class filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [],
      startDate: 2000,
      endDate: new Date().getFullYear(),
      docID: "",
      width: 0,
      height: 0,
      years: Array.from(
        { length: new Date().getFullYear() - 2000 },
        (_, i) => i + 2000
      ),
    };
    // this.props.applyFilter.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  toggleType(type) {
    const { types } = this.state;
    if (types.includes(type)) {
      var index = types.indexOf(type);
      types.splice(index, 1);
    } else {
      types.push(type);
    }
    this.setState({ types });
  }

  componentDidMount() {
    let self = this;
    loadjs.reset();
    loadjs("./js/projects-grid.js", function () {});
    loadjs("./js/accordion.js", function () {});
    loadjs("./js/rangeslider.js", function () {
      $("#reset-filter").on("click", function () {
        self.setState({
          types: [],
          startDate: "",
          endDate: "",
          docID: "",
          width: 0,
          height: 0,
        });
      });

      $("#reset-filter-sm").on("click", function () {
        self.setState({
          types: [],
          startDate: "",
          endDate: "",
          docID: "",
          width: 0,
          height: 0,
        });
      });
    });
    loadjs("./js/rangeslider-resp.js", function () {});
    // $("#datepicker").datepicker({ dateFormat: "yy" });
    // $("#datepicker1").datepicker({ dateFormat: "yy" });
    // $("#datepicker2").datepicker({ dateFormat: "yy" });
    // $("#datepicker3").datepicker({ dateFormat: "yy" });
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  render() {
    let { years } = this.state;
    return (
      <div id="filterModal" className="filter-modal">
        <div className="filter-content">
          <div className="filter-intro">
            <div className="filter-title">
              Filter By <span>&#9473;&#9473;</span>
            </div>
            <a className="lg" id="reset-filter">
              Reset Filters
            </a>
          </div>

          <div className="filter-accordion-div">
            <div className="accordion">
              <div className="service-name"> Industry </div>
            </div>
            <div className="panel">
              <div className="filter-checkbox ">
                <CheckBox
                  name="Commercial"
                  toggleType={this.toggleType.bind(this)}
                ></CheckBox>
                <CheckBox
                  name="Medical"
                  toggleType={this.toggleType.bind(this)}
                ></CheckBox>
                <CheckBox
                  name="Heritage Restoration"
                  toggleType={this.toggleType.bind(this)}
                ></CheckBox>

                <CheckBox
                  name="Residential"
                  toggleType={this.toggleType.bind(this)}
                ></CheckBox>
                <CheckBox
                  name="Hospitality / Hotels"
                  toggleType={this.toggleType.bind(this)}
                ></CheckBox>
                <CheckBox
                  name="Military"
                  toggleType={this.toggleType.bind(this)}
                ></CheckBox>
                <CheckBox
                  name="Administrative"
                  toggleType={this.toggleType.bind(this)}
                ></CheckBox>
                <CheckBox
                  name="Landscaping"
                  toggleType={this.toggleType.bind(this)}
                ></CheckBox>
              </div>
            </div>
          </div>
          <div className="filter-accordion-div">
            <div className="accordion">
              <div className="service-name"> Year </div>
            </div>
            <div className="panel">
              <div className="date-holder">
                <div className="from-holder">
                  <span id="dateTitle">From</span>
                  {/* <input
                    type="number"
                    min="2000"
                    max="2100"
                    className="input-1 sm"
                    placeholder="From"
                    onChange={(event) =>
                      this.setState({ startDate: event.target.value })
                    }
                  ></input> */}
                  <select
                    className="input-1 sm"
                    onChange={(event) =>
                      this.setState({ startDate: event.target.value })
                    }
                  >
                    {years.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="to-holder">
                  <span id="dateTitle">To</span>
                  <select
                    className="input-1 sm"
                    onChange={(event) =>
                      this.setState({ endDate: event.target.value })
                    }
                  >
                    {years.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                  {/* <input
                    type="number"
                    min="2000"
                    max="2100"
                    className="input-2 sm"
                    placeholder="To"
                    onChange={(event) =>
                      this.setState({ endDate: event.target.value })
                    }
                  ></input> */}
                </div>
              </div>
            </div>
          </div>

          <div className="filter-accordion-div">
            <div className="accordion">
              <div className="service-name"> Project Value </div>
            </div>
            <div className="panel">
              <div className={`multi-range-slider`}>
                <input
                  type="range"
                  id="input-left-sm"
                  min="0"
                  max="100"
                ></input>
                <input
                  type="range"
                  id="input-right-sm"
                  min="0"
                  max="100"
                ></input>
                <div className="slider sm">
                  <div className="track"></div>
                  <div className="range"></div>
                  <div className="thumb left"></div>
                  <div className="thumb right"></div>
                </div>
                <div className="filter-price">
                  <div className="leftPrice sm">EGP 1M</div>
                  <div className="rightPrice sm">EGP 500M</div>
                </div>
              </div>
            </div>
          </div>

          <div className="filters-lg">
            <div className="filter-industry">Industry:</div>
            <div className="filter-checkbox ">
              <CheckBox
                name="Commercial"
                toggleType={this.toggleType.bind(this)}
              ></CheckBox>
              <CheckBox
                name="Medical"
                toggleType={this.toggleType.bind(this)}
              ></CheckBox>
              <CheckBox
                name="Industrial"
                toggleType={this.toggleType.bind(this)}
              ></CheckBox>
              <CheckBox
                name="Heritage Restoration"
                toggleType={this.toggleType.bind(this)}
              ></CheckBox>
              <CheckBox
                name="Residential"
                toggleType={this.toggleType.bind(this)}
              ></CheckBox>
              <CheckBox
                name="Hospitality / Hotels"
                toggleType={this.toggleType.bind(this)}
              ></CheckBox>
              <CheckBox
                name="Military"
                toggleType={this.toggleType.bind(this)}
              ></CheckBox>
              <CheckBox
                name="Administrative"
                toggleType={this.toggleType.bind(this)}
              ></CheckBox>
              <CheckBox
                name="Landscaping"
                toggleType={this.toggleType.bind(this)}
              ></CheckBox>
            </div>
            <div className="filter-year">Year:</div>

            <div className="date-holder">
              <div className="from-holder">
                <span id="dateTitle">From</span>
                {/* <input
                  type="number"
                  min="2000"
                  max="2100"
                  className="input-1 lg"
                  placeholder="From"
                  onChange={(event) =>
                    this.setState({ startDate: event.target.value })
                  }
                ></input> */}
                <select
                  className="input-1 sm"
                  onChange={(event) =>
                    this.setState({ startDate: event.target.value })
                  }
                >
                  {years.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
              <div className="to-holder">
                <span id="dateTitle">To</span>
                <select
                  className="input-1 sm"
                  onChange={(event) =>
                    this.setState({ endDate: event.target.value })
                  }
                >
                  {years.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
                {/* <input
                  type="number"
                  min="2000"
                  max="2100"
                  className="input-2 lg"
                  placeholder="To"
                  onChange={(event) =>
                    this.setState({ endDate: event.target.value })
                  }
                ></input> */}
              </div>
            </div>
            <div className="filter-project">Project Value:</div>
            <div
              className={`multi-range-slider ${
                this.state.width > 900 ? "active-filter" : ""
              }`}
            >
              <input type="range" id="input-left" min="0" max="100"></input>
              <input type="range" id="input-right" min="0" max="100"></input>
              <div className="slider lg">
                <div className="track"></div>
                <div className="range"></div>
                <div className="thumb left"></div>
                <div className="thumb right"></div>
              </div>
              <div className="filter-price">
                <div className="leftPrice lg">EGP 1M</div>
                <div className="rightPrice lg">EGP 500M</div>
              </div>
            </div>
          </div>

          <button
            onClick={() => {
              const { types, startDate, endDate } = this.state;
              let leftValue =
                this.state.width > 900
                  ? window.getLeftValue()
                  : window.getLeftValueSM();
              let rightValue =
                this.state.width > 900
                  ? window.getRightValue()
                  : window.getRightValueSM();
              this.props.applyFilter({
                types,
                startDate,
                endDate,
                leftValue,
                rightValue,
              });
              window.toggleFilterWindow();
            }}
            className=" button button--isi button--isi--dirty-blue button--text-thick button--text-upper button--size-s"
          >
            Apply Filters
          </button>
          <div className="reset-filter-sm">
            <a id="reset-filter-sm" className="sm">
              Reset Filters
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default filter;
