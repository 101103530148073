import React, { useState } from "react";
import Footer from "../Footer";
import { fireapp } from "../../firebase/firebaseConfig";
import { Axios, db } from "../../firebase/firebaseConfig";
import Swal from "sweetalert2";
import $ from "jquery";
import AOS from "aos";
// import { withRouter } from "react-router-dom";
import upload from "../../../src/assets/img/careers/upload@2x.png";
// var loadjs = require("loadjs");
var file = "";
var fileRef = "";
var storageRef = "";
const Careers = () => {
  AOS.init({ duration: 1500, once: true });
  const [formData, setFormData] = useState({});
  const [careers, setCareers] = useState([]);

  React.useEffect(() => {
    // alert("ddd");
    var loadjs = require("loadjs");
    loadjs("./js/contact-drop.js", function () {
      $("div.svg").on("click", function () {
        document.getElementById("application").scrollIntoView({
          behavior: "smooth",
        });
      });
    });
    getCareers();
  }, []);

  const buttonText = document.getElementById("SendingButton");
  const getCareers = () => {
    db.collection("careers")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          console.log(data);
          setCareers((careers) => [...careers, doc.data()]);
        });
      })

      .catch((error) => console.log(error));
  };

  const updateInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (event) => {
    if (buttonText) {
      buttonText.innerHTML = "SENDING..";
    }

    event.preventDefault();
    event.preventDefault();
    fileRef.put(file).then(() => {
      console.log("uploaded !!!");
    });
    sendEmail();
    setFormData({
      name: "",
      email: "",
      message: "",
      mobile: "",
      reason: "wants to join the team",
    });
  };
  const sendEmail = () => {
    formData.reason = "wants to join the team";
    Axios.post(
      "https://us-central1-palm-construction.cloudfunctions.net/submit",
      formData
    )
      .then((res) => {
        console.log(formData);
        db.collection("emails").doc(formData.name).set({
          name: formData.name,
          email: formData.email,
          message: formData.message,
          mobile: formData.mobile,
          reason: "wants to join the team",
          time: new Date(),
        });
        buttonText.innerHTML = "SENT";
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your message has been received",
          showConfirmButton: false,
          timer: 2000,
        }).then(function () {
          window.location = "/";
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onChange = (e) => {
    file = e.target.files[0];
    console.log(file);
    storageRef = fireapp.storage().ref();
    fileRef = storageRef.child("Resumes").child(file.name);
    if (document.getElementById("upload-label")) {
      fileRef.put(file).then(function (snapshot) {
        snapshot.ref.getDownloadURL().then(function (downloadUrl) {
          console.log(downloadUrl);
        });
      });
      document.getElementById("upload-label").innerHTML = file.name;
    }
  };

  return (
    <div className="careers-main">
      <div className="header-careers">
        <div data-aos="fade-down" className="careers-title">
          Current Vacancies
        </div>
        <div data-aos="fade-up" className="secondary-title">
          These are the roles we are actively recruiting. If you're interested,
          please apply through Wuzzuf.
        </div>
        <div className="careers-container">
          {careers &&
            careers.map((career, idx) => {
              return career.open ? (
                <div data-aos="fade-up" key={idx} className="career-instance">
                  <div className="career-type">{career.title}</div>

                  <a
                    href={career.redirection_link}
                    className="svg apply-button"
                  >
                    <div className="career-apply"></div>
                    <svg>
                      <circle className="anicircle" />
                    </svg>
                  </a>
                </div>
              ) : null;
            })}
        </div>
        <button
          data-aos="fade-up"
          href="https://wuzzuf.com"
          className="button button--isi button--isi--white  button--text-upper button--size-s"
        >
          View All Vacancies
        </button>
      </div>
      <div id="application" className="career-application">
        <div className="career-application-overlay" />

        <div className="second-div">
          <div data-aos="fade-right" className="fillapp-title">
            Not sure which role is best for you?
          </div>
          <div data-aos="fade-left" className="fillapp-secondary">
            Fill in our application form and we will help you out.
          </div>
          <div data-aos="fade-left" className="application-form">
            <div className="app-title text-uppercase">Application Form</div>
            <form onSubmit={handleSubmit}>
              <span class="input input--chisato">
                <input
                  class="input__field input__field--chisato"
                  type="text"
                  id="input-13"
                  name="name"
                  onChange={updateInput}
                />
                <label
                  class="input__label input__label--chisato"
                  for="input-13"
                >
                  <span
                    class="input__label-content input__label-content--chisato"
                    data-content="Name"
                  >
                    Name
                  </span>
                </label>
              </span>
              <span class="input input--chisato">
                <input
                  class="input__field input__field--chisato"
                  type="email"
                  id="input-13"
                  name="email"
                  onChange={updateInput}
                />
                <label
                  class="input__label input__label--chisato"
                  for="input-13"
                >
                  <span
                    class="input__label-content input__label-content--chisato"
                    data-content="Email"
                  >
                    Email
                  </span>
                </label>
              </span>
              <span class="input input--chisato">
                <input
                  class="input__field input__field--chisato"
                  type="number"
                  id="input-13"
                  name="mobile"
                  onChange={updateInput}
                />
                <label
                  class="input__label input__label--chisato"
                  for="input-13"
                >
                  <span
                    class="input__label-content input__label-content--chisato"
                    data-content="Mobile"
                  >
                    Mobile
                  </span>
                </label>
              </span>
              <span class="input input--chisato">
                <textarea
                  class="input__field input__field--chisato input-textarea"
                  type="text"
                  id="input-13"
                  name="message"
                  onChange={updateInput}
                />
                <label
                  class="input__label input__label--chisato"
                  for="input-13"
                >
                  <span
                    class="input__label-content input__label-content--chisato"
                    data-content="Purpose"
                  >
                    Why you want to join our team?
                  </span>
                </label>
              </span>
              <div className="upload-div">
                <input type="file" onChange={onChange}></input>
                <button className="upload-button ">
                  <img src={upload} alt=""></img>
                </button>
                <div className="upload-title">
                  <div id="upload-label">Upload Your CV</div>
                </div>
              </div>
              <button
                type="submit"
                className="button button--isi button--isi--dirty-blue button--text-thick button--text-upper button--size-s"
              >
                <span id="SendingButton"> Submit</span>
              </button>
            </form>
          </div>
        </div>
        <Footer
          background={{ background: "transparent", paddingTop: "10rem" }}
        />
      </div>
    </div>
  );
};

export default Careers;
