import React, { Component } from "react";
import AOS from "aos";
export class Mission extends Component {
  render() {
    AOS.init({ duration: 1500, delay: 200 });

    return (
      <section className="section-mission">
        <div className="vision-section" id="portfolio">
          <div className="footer-overlay"></div>
          <div className="container">
            <div className="row" style={{ padding: "0 0rem" }}>
              <div className="col-lg-12 text-left">
                <div
                  data-aos="fade-up"
                  data-aos-delay="400"
                  className="section-heading text-uppercase Mission"
                >
                  Mission
                </div>
                <div
                  data-aos="fade-down"
                  data-aos-delay="400"
                  className="section-subheading To-form-long-term-r"
                >
                  {this.props.about.mission}
                </div>
              </div>
            </div>
            <div className="MissionMargins">
              <div
                data-aos="fade-up"
                data-aos-delay="400"
                className="coreValues"
              >
                <p>
                  Core Values
                  <span style={{ marginLeft: "15px" }}>
                    &#9473;&#9473;&#9473;
                  </span>
                </p>
              </div>
              <div
                data-aos="fade-down"
                data-aos-delay="600"
                className="container-fluid"
              >
                <div className="row">
                  <div className="col-md-4 col-sm-6 col-xs-6 mission-item">
                    <div className="portfolio-item ">
                      To Respect the value of differences and sincere intentions
                      as the basis for achieving shared aspirations.
                    </div>
                    <div className="portfolio-caption">Respect</div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-xs-6  mission-item">
                    <div className="portfolio-item">
                      To Promote the innovation and research in its application
                      to infrastructure development.
                    </div>
                    <div className="portfolio-caption">Innovation</div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-xs-6  mission-item">
                    <div className="portfolio-item">
                      We value cooperation, using our individual skills and
                      providing constructive feedback to get everyone on the
                      same page; we work collaboratively with people and
                      organizations in order to achieve a goal.
                    </div>
                    <div className="portfolio-caption">Team Work</div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-xs-6  mission-item">
                    <div className="portfolio-item">
                      The ability to stand up for what is morally right and to
                      fulfill commitments and promises.
                    </div>
                    <div className="portfolio-caption">Integrity</div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-xs-6  mission-item">
                    <div className="portfolio-item ">
                      Creating a difference in quality construction, providing
                      international quality standards.
                    </div>
                    <div className="portfolio-caption">Quality</div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-xs-6  mission-item">
                    <div className="portfolio-item">
                      We are committed to sustain development, to serve society
                      efficiently and ethically in a responsible manner
                      supported by potentiality to create value for shareholders
                      and all stakeholders.
                    </div>
                    <div className="portfolio-caption">Commitment</div>
                  </div>
                  <div className="gradient-line"></div>
                  <div className="gradient-line-1"></div>
                  <div className="gradient-line-horizontal"></div>
                  <div className="gradient-line-horizontal-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Mission;
