import React, { Component } from "react";

import "owl.carousel/dist/assets/owl.carousel.css";

import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "../Footer";
import navyButton from "../../assets/img/singleProject/back-navy.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import { db } from "../../firebase/firebaseConfig";
var loadjs = require("loadjs");
let loaded_images = [];
export default class singleProject extends Component {
  state = {
    images: [],
    project: {},
    projects: [],
    date: "",
    index: 0,
    id: "",
  };
  getProjectImages() {
    db.collection("projects")
      .doc(this.props.history.location.state.id)
      .get()
      .then((doc) => {
        var project = doc.data();
        project["id"] = doc["id"];
        this.updateProjectImages(project);
        this.setState({ project });
        console.log(project);
        this.setState({
          date: project.date,
        });
        if (
          this.props.location.state["projects"] &&
          this.props.location.state["projects"].length > 0
        ) {
          this.setState({
            projects: this.props.location.state["projects"],
            index: this.props.location.state["index"],
          });
        } else {
          this.getProjectsData();
        }
      })
      .catch((error) => console.log(error));
  }
  getProjectImagesFunc() {
    console.log(this.props.history.location.pathname.split("/"));
    db.collection("projects")
      .doc(this.props.history.location.pathname.split("/").pop())
      .get()
      .then((doc) => {
        var project = doc.data();
        project["id"] = doc["id"];
        this.updateProjectImages(project);
        this.setState({ project });
        if (this.props.location["projects"]) {
          this.setState({
            projects: this.props.location["projects"],
            index: this.props.location["index"],
          });
        } else {
          this.getProjectsData();
        }
      })
      .catch((error) => console.log(error));
  }

  getProjectsData = () => {
    var counter = 0;
    db.collection("projects")
      .get()
      .then((snapshot) => {
        const projects = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.ref.id;
          if (data["id"] === this.state.project["id"]) {
            this.setState({ index: counter });
          } else {
            counter++;
          }
          projects.push(data);
        });
        this.setState({ projects: projects });
        console.log(projects);
      })

      .catch((error) => console.log(error));
  };
  updateProjectImages(project) {
    // loadjs("./js/owl-carousel.js", function () {});

    const images = [];
    let images_data = project["images"];
    images_data.forEach((image) => {
      images.push(image.url);
      loaded_images.push(image.url);
    });
    this.setState({ images });
    loaded_images = [];
  }
  componentDidMount() {
    if (this.props.history.location.state === undefined) {
      this.getProjectImagesFunc();
    } else {
      this.getProjectImages();
    }
    window.scrollTo(0, 0);
    // this.getProjectImages();
    document.getElementById("project-content").scrollIntoView({
      behavior: "smooth",
    });
  }

  goBack() {
    this.props.history.push(`/projects-grid`);
  }

  Detailspage({ id }) {
    this.props.history.push(`/projects/${id}`, {
      id,
    });

    window.location.reload();
  }

  nextPage() {
    let { index, projects } = this.state;
    console.log(this.state);
    index++;
    if (index >= projects.length) {
      index = 0;
    }
    this.setState({ index });
    let nextProject = projects[index];

    let { id } = nextProject;
    this.Detailspage({ id });
    // this.setState({ project: nextProject });
    // this.updateProjectImages(nextProject);
  }
  prevPage() {
    let { index, projects } = this.state;
    index--;
    if (index <= -1) {
      index = projects.length - 1;
    }
    this.setState({ index });
    let nextProject = projects[index];
    let { id } = nextProject;
    this.Detailspage({ id });
    // this.setState({ project: nextProject });
    // this.updateProjectImages(nextProject);
  }

  render() {
    let { project } = this.state;
    console.log("Project ==> ", project);
    return (
      <div className="project-header">
        <div className="single-container">
          <div className="single-container-overlay" />

          <div id="project-content" className="main-div ">
            <div>
              <a href="/projects-grid" className="projectsBack">
                <img alt="" src={navyButton} />
                <div>Back to Projects</div>
              </a>
            </div>

            <div className="owl-carousel">
              {this.state.images &&
                this.state.images.map((image, index) => {
                  var $ = window.$;

                  loadjs("./js/owlCarousel.js", function () {
                    $(".owl-carousel")
                      .on(
                        "initialized.owl.carousel changed.owl.carousel",
                        function (e) {
                          // var activeClassDividedByTwo = Math.ceil($(".active").length / 2);
                          //Adding the activeClassDividedByTwo (is 3 on larg screens)
                          // let OwlNumber = $(".owl-item.active").length;
                          //Rmove any 'big' class
                          $(".owl-item")
                            .children(".img_box")
                            .removeClass("big");
                          $(".owl-item")
                            .children(".img_box")
                            .removeClass("next");
                          $(".owl-item")
                            .children(".img_box")
                            .removeClass("pre");
                          //Adding new 'big' class to the fourth .item

                          $(".owl-item")
                            .eq(e.item.index - 1)
                            .children(".img_box")
                            .addClass("big");
                          $(".owl-item")
                            .eq(e.item.index - 1)
                            .children(".img_box")
                            .addClass("pre");
                          $(".owl-item")
                            .eq(e.item.index + 1)
                            .children(".img_box")
                            .addClass("big");
                          $(".owl-item")
                            .eq(e.item.index + 1)
                            .children(".img_box")
                            .addClass("next");
                          if (!e.namespace) {
                            return;
                          }
                          var carousel = e.relatedTarget;
                          if (carousel.items().length === 1) {
                            $(".owl-item")
                              .children(".img_box")
                              .removeClass("big");
                            $(".owl-item")
                              .children(".img_box")
                              .removeClass("next");
                            $(".owl-item")
                              .children(".img_box")
                              .removeClass("pre");
                          }

                          $(".slider-counter").text(
                            carousel.relative(carousel.current()) + 1
                          );
                          $(".slider-counter2").text(
                            "/ " + carousel.items().length
                          );
                        }
                      )
                      .owlCarousel({
                        nav: true,
                        dots: true,
                        center: true,
                        responsiveClass: true,
                        autoHeight: true,
                        responsive: {
                          0: {
                            items: 1,
                            nav: false,
                            // margin: 0,
                          },
                          900: {
                            items: 3,
                            nav: false,
                          },
                        },
                        navText: [
                          $(".carousel-control-prev"),
                          $(".carousel-control-next"),
                        ],
                      });

                    $("carousel-control-next").click(function () {
                      // var count = $(".main-div").find(".pagination").html();
                    });
                  });
                  return (
                    <div key={index} className="img_box">
                      <img className="img" src={image} alt="" />
                    </div>
                  );
                })}
            </div>

            <div className="counter-div">
              <a
                className="carousel-control-prev"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
              <span className="slider-counter"></span>
              <span className="slider-counter2"></span>
            </div>
          </div>
        </div>
        <div className="project-description ">
          <div className="project-description-overlay" />
          <div className="description-div ">
            <div className="description-title ">{project.title}</div>
            <div className="description-headline ">{project.client}</div>
            <div className="description-content">{project.description}</div>
            <div className="row">
              <div className="col-md-4 col-sm-6 col-xs-6  details-div">
                <div className="details1">Client :</div>
                <div className="details2">
                  {project.client_image ? (
                    <img src={project.client_image} alt="Not Available"></img>
                  ) : (
                    <React.Fragment>{project.partner_name}</React.Fragment>
                  )}
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-6  details-div">
                <div className="details1 details3">Year :</div>
                <div className="details2 details3 ">
                  {project.date ? (
                    <React.Fragment>{project.date}</React.Fragment>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-6  details-div">
                <div className="details1 details3">Area :</div>
                <div className="details2 details3">{project.area}</div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-6  details-div">
                <div className="details1">Project Duration :</div>
                <div className="details2">
                  <React.Fragment>{project.duration}</React.Fragment>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-6  details-div">
                <div className="details1 details3">Project Value :</div>
                <div className="details2 details3">
                  {project.price ? (
                    <React.Fragment>
                      {project.price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      &nbsp;
                      {project.currency ? (
                        <React.Fragment>{project.currency}</React.Fragment>
                      ) : (
                        <React.Fragment>EGP</React.Fragment>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-6  details-div">
                <div className="details1 details3">Location :</div>
                <div className="details2 details3">{project.location}</div>
              </div>
            </div>
            <div className="gradient-line-horizontal"></div>
          </div>
          <div className="buttons-div">
            <button
              className="prev-a button button--isi button--isi--white button--text-thick button--text-upper "
              onClick={() => this.prevPage()}
            >
              <div className="theimage" />

              <div>Previous Project</div>
            </button>
            <button
              className="next-a button button--isi button--isi--dirty-blue button--text-thick button--text-upper"
              onClick={() => this.nextPage()}
            >
              <div className="theimage" />

              <div>Next Project</div>
            </button>
          </div>
          <Footer background={{ background: "transparent" }} />
        </div>
      </div>
    );
  }
}
