import React, { useState } from "react";
import { Axios, db } from "../../firebase/firebaseConfig";
import imagePlace from "../../assets/img/contact/place.png";
import imageTime from "../../assets/img/contact/phone.png";
import imageMail from "../../assets/img/contact/email.png";
import Footer from "../Footer";
import { fireapp } from "../../firebase/firebaseConfig";

import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import AOS from "aos";
import upload from "../../../src/assets/img/careers/upload@2x.png";

var file = "";
var fileRef = "";
var storageRef = "";
const Contact = () => {
  AOS.init({ duration: 1500 });
  React.useEffect(() => {
    var loadjs = require("loadjs");
    loadjs("./js/contact-drop.js", function () {});
  });

  //   const { send, handleSubmit, error } = useForm();

  const [formData, setFormData] = useState({});
  const [choice, setChoice] = useState({});
  const selected = document.querySelector(".selected");

  const buttonText = document.getElementById("SendingButton");

  var div = document.getElementsByClassName("option")[0];
  if (div) {
    div.addEventListener("click", function (event) {
      if (selected) {
      }
      setChoice(selected.innerHTML);
      setFormData({
        ...formData,
        reason: choice,
      });
    });
  }
  const onChange = (e) => {
    file = e.target.files[0];
    console.log(file);
    storageRef = fireapp.storage().ref();
    fileRef = storageRef.child("Contact").child(file.name);
    if (document.getElementById("upload-label")) {
      fileRef.put(file).then(function (snapshot) {
        snapshot.ref.getDownloadURL().then(function (downloadUrl) {
          console.log(downloadUrl);
        });
      });
      document.getElementById("upload-label").innerHTML = file.name;
    }
  };
  const updateInput = (e) => {
    if (selected) {
      setChoice(selected.innerHTML);
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      reason: choice,
    });
  };

  const handleSubmit = (event) => {
    if (buttonText) {
      buttonText.innerHTML = "SENDING..";
    }

    event.preventDefault();
    event.preventDefault();
    fileRef.put(file).then(() => {
      console.log("uploaded !!!");
    });

    event.preventDefault();
    sendEmail();
    setFormData({
      name: "",
      email: "",
      message: "",
      mobile: "",
      reason: "Reason of contact",
    });
  };

  const sendEmail = () => {
    formData.reason = selected.innerHTML;

    Axios.post(
      "https://us-central1-palm-construction.cloudfunctions.net/submit",
      formData
    )
      .then((res) => {
        console.log(formData);
        db.collection("emails").add({
          name: formData.name,
          email: formData.email,
          message: formData.message,
          mobile: formData.mobile,
          reason: selected.innerHTML,
          time: new Date(),
        });
        buttonText.innerHTML = "SENT";
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your message has been received",
          showConfirmButton: false,
          timer: 2000,
        }).then(function () {
          window.location = "/";
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <React.Fragment>
      <section className="section-contact">
        <div className="header-contact">
          <div className="header-contact-overlay" />
          <div
            data-aos="fade-right"
            data-aos-delay="250"
            className="contact-div"
          >
            <div className="container contact-container">
              <div className="contact-title">Contact Information</div>
              <div className="contact-time">
                We are at the office from Sunday - Thursday
                <span>10:00 AM - 6:00 PM</span>
              </div>
              <div className="location-container">
                <img alt="" src={imagePlace} />
                <a
                  href="https://www.google.com/maps/search/?api=1&query=30.050860,31.344861"
                  className="contact-location"
                >
                  Mohammed Ahmed Ibrahim,{" "}
                  <span> Al Manteqah Ath Thamenah, Nasr City,</span>
                  <span>Cairo Governorate</span>
                </a>
              </div>
              <div className="time-container">
                <img alt="" src={imageTime} />
                <div className="contact-number">
                  +2 227 190 37<span>+2 010188 683 96</span>
                </div>
              </div>
              <div className="mail-container">
                <img alt="" src={imageMail} />
                <div className="contact-mail">Info@plamgpconstruction.com</div>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" className="contact-form">
            <div className="form-title text-uppercase">Send us a message</div>
            <form onSubmit={handleSubmit}>
              <span class="input input--chisato">
                <input
                  class="input__field input__field--chisato"
                  type="text"
                  id="input-13"
                  name="name"
                  onChange={updateInput}
                  value={formData.name || ""}
                />
                <label
                  class="input__label input__label--chisato"
                  for="input-13"
                >
                  <span
                    class="input__label-content input__label-content--chisato"
                    data-content="Name"
                  >
                    Name
                  </span>
                </label>
              </span>
              <span class="input input--chisato">
                <input
                  class="input__field input__field--chisato"
                  type="text"
                  id="input-13"
                  name="email"
                  onChange={updateInput}
                  value={formData.email || ""}
                />
                <label
                  class="input__label input__label--chisato"
                  for="input-13"
                >
                  <span
                    class="input__label-content input__label-content--chisato"
                    data-content="Email"
                  >
                    Email
                  </span>
                </label>
              </span>
              <span class="input input--chisato">
                <input
                  class="input__field input__field--chisato"
                  type="text"
                  id="input-13"
                  name="mobile"
                  onChange={updateInput}
                  value={formData.mobile || ""}
                />
                <label
                  class="input__label input__label--chisato"
                  for="input-13"
                >
                  <span
                    class="input__label-content input__label-content--chisato"
                    data-content="Mobile"
                  >
                    Mobile
                  </span>
                </label>
              </span>
              <div class="select-box">
                <div id="myoption" class="options-container">
                  <div id="project" className="option">
                    <input type="radio" class="radio" name="category" />
                    <label for="project"> start a project</label>
                  </div>

                  <div id="team" class="option">
                    <input type="radio" class="radio" name="category" />
                    <label for="team">I want to join the team</label>
                  </div>
                </div>

                <div class="selected">Reason of contact</div>
              </div>
              <span class="input input--chisato">
                <textarea
                  class="input__field input__field--chisato input-textarea"
                  type="text"
                  id="input-13"
                  name="message"
                  onChange={updateInput}
                  value={formData.message || ""}
                />
                <label
                  class="input__label input__label--chisato"
                  for="input-13"
                >
                  <span
                    class="input__label-content input__label-content--chisato"
                    data-content="Message"
                  >
                    Your message
                  </span>
                </label>
              </span>
              <div className="upload-div">
                <input type="file" onChange={onChange}></input>
                <button className="upload-button ">
                  <img alt="" src={upload}></img>
                </button>
                <div className="upload-title">
                  <div id="upload-label">Upload Your Attachment</div>
                </div>
              </div>
              <button
                onClick={handleSubmit}
                value="aaaaa"
                type="submit"
                className="button button--isi button--isi--dirty-blue button--text-thick button--text-upper button--size-s"
              >
                <span id="SendingButton">Send</span>
              </button>{" "}
            </form>
          </div>
        </div>
        <div className="footer-div">
          <div className="footer-overlay" />
        </div>
        <Footer />
      </section>
    </React.Fragment>
  );
};

export default withRouter(Contact);
