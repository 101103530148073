import React from "react";
// import { Carousel, Container, Row, Col } from "react-bootstrap";
// import Logo from "../../assets/img/palm-logo/image-1@2x.png";
import AOS from "aos";
import { useHistory } from "react-router";

function returnStyle(image) {
  return {
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75)), ${image}`,
  };
}
const Projects = (projects) => {
  var history = useHistory();
  function navigate(id, projects) {
    history.push({
      pathname: `/projects/${id}`,

      id,
      projects,
    });
  }

  var mobile = false;
  if (window.matchMedia("(min-width: 900px)").matches) {
    mobile = false;
  } else {
    mobile = true;
  }
  AOS.init({ duration: 1500 });
  return (
    <React.Fragment>
      <section className="section-projects">
        <div className="section-projects-overlay"></div>
        <div data-aos="fade-up" className="section-projects-header">
          <h3 className="section-header-projects ">our latest projects</h3>
        </div>

        <div className="container-fluid projects">
          <div className="row">
            {projects.projects &&
              projects.projects.map((project, idx) => {
                let display_image = "";
                if (
                  project.display_images != null &&
                  project.display_images.length > 0
                )
                  if (Array.isArray(project.display_images)) {
                    display_image = project.display_images[0].url;
                  } else {
                    display_image = project.display_images;
                  }
                else {
                  display_image =
                    "https://previews.123rf.com/images/pavelstasevich/pavelstasevich1811/pavelstasevich181101027/112815900-no-image-available-icon-flat-vector.jpg";
                }
                // console.log(project);
                return (
                  <div
                    key={idx}
                    data-aos={idx % 2 === 0 ? "fade-up-left" : "fade-up-right"}
                    className="col-md-6 col-sm-12 project-card"
                  >
                    <div
                      onClick={() => navigate(project.id, projects)}
                      className="project-div"
                      style={returnStyle(`url('${display_image}')`)}
                    >
                      <div className="project-data">
                        <div className="project-name">
                          <div className="type">
                            {project.title}

                            {/* {project.partner_name ? (
                              <React.Fragment>
                                &nbsp;| {project.partner_name}
                              </React.Fragment>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )} */}
                          </div>
                          <div className="name">{project.client}</div>
                        </div>
                        <div className="svg">
                          <div className="circle-button"></div>
                          {mobile ? (
                            <svg width="60" height="60">
                              <circle
                                className="anicircle"
                                cx="32"
                                cy="30"
                                r="18"
                              />
                            </svg>
                          ) : (
                            <svg width="70" height="70">
                              <circle
                                className="anicircle"
                                cx="35"
                                cy="35"
                                r="30"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Projects;
