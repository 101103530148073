import React, { Component } from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import Footer from "../../Footer";
import path from "../../../assets/img/path.svg";
import { db } from "../../../../src/firebase/firebaseConfig";
import AOS from "aos";
var loadjs = require("loadjs");

export class Header extends Component {
  state = {
    crew: [],
    management: [],
  };
  componentWillMount() {
    // this.fetchProjects();
  }
  componentDidMount() {
    this.getTeamsDetails();
    AOS.init({ duration: 1500 });
  }
  returnStyle(image) {
    return {
      backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.8)), url(${image})`,
      backgroundSize: "cover, cover",
      backgroundPosition: "center",
    };
  }

  getTeamsDetails() {
    db.collection("teams")

      .get()
      .then((snapshot) => {
        const crew = [];
        const management = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.ref.id;
          if (doc.data().type === "crew") crew.push(data);
          else management.push(data);
        });
        this.setState({ crew: crew, management: management });
        loadjs("./js/multi-item.js", function () {});
      })

      .catch((error) => console.log(error));
  }
  render() {
    return (
      <div className="meet-the-team">
        <div className="path-div">
          <img src={path} alt="" />
        </div>
        <div>
          {/* <div className="flexcontainer actives">
          <a href="#our-story">Our Story</a>
          <a href="#ceo-message">CEO message</a>
          <a href="the-team">The Team</a>
        </div> */}
          <section className="section-about-team ">
            <div className="flexcontainer header-team actives">
              <div className="header-team-overlay"></div>
              <div data-aos="fade-up" className="sidenav">
                <a href="about">Our Story</a>
                <a href="ceo-message">CEO message</a>
                <a className="active-link" href="team">
                  The Team
                </a>
              </div>
              <div className="header-team__text-box">
                <h2 data-aos="fade-down">MEET </h2>
                <h2 data-aos="fade-up">THE TEAM</h2>
              </div>
            </div>
          </section>
          <section className="section-management">
            <div className="section-management-overlay"></div>
            <div className="u-margin-bottom-extra-big management-header">
              <h2 data-aos="fade-up" className="heading-secondary">
                Management
              </h2>
            </div>

            <Carousel
              data-aos="fade-up"
              id="sampleSlide"
              indicators={false}
              className={"carousel slide multi-item-carousel"}
              interval={false}
            >
              {this.state.management &&
                this.state.management.map((member) => {
                  return (
                    <Carousel.Item>
                      <div data-aos="fade-up" className="item__third">
                        <Container className={[""]}>
                          <Row>
                            <Col
                              md={12}
                              sm={12}
                              xs={12}
                              className={["", "management-team-card"]}
                            >
                              <div
                                className="management-div"
                                style={this.returnStyle(member.image)}
                              >
                                <div className="management-data">
                                  <div className="name">{member.name}</div>
                                  <div className="position">
                                    {member.position}
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </Carousel.Item>
                  );
                })}
            </Carousel>

            <a
              className="carousel-control-prev updated_carousel-control"
              href="#sampleSlide"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next updated_carousel-control"
              href="#sampleSlide"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </section>
          <section className="section-crew">
            <div className="section-crew-overlay"></div>
            <div className="crew-container">
              <div className="u-margin-bottom-extra-big section-crew-header">
                <h2 data-aos="fade-up" className="heading-secondary">
                  The Crew
                </h2>
              </div>

              <div className="">
                <Row>
                  {this.state.crew &&
                    this.state.crew.map((member) => {
                      return (
                        <Col
                          md={4}
                          sm={12}
                          xs={12}
                          className="section-crew--item"
                        >
                          <div data-aos="fade-up" className="crew-card">
                            <div className="crew-img">
                              <img src={member.image} alt="" />
                            </div>
                            <div className="crew-name">
                              <h4>{member.name}</h4>
                            </div>
                            <div className="crew-position">
                              <h5>{member.position}</h5>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Header;
