import React, { Component } from "react";
import { Router, Route, Switch, withRouter } from "react-router-dom";
import history from "../history";
import Navbar from "./Navbar";
import Home from "./home/Home.jsx";
import About from "./About/About";
import Team from "./About/Team/Team";
import Ceo from "./About/Ceo/Ceo";
import ProjectsGrid from "./projects/Projects-grid";
import ProjectsTile from "./projects/Projects-tile";
import contact from "./contact/Contact";
import careers from "./careers/careers";
import singleproject from "./projects/single-project";
import news from "./news/news";
import article from "./news/articleDetails";
import partners from "./partners/partners";
import sample from "./projects/sampleProject";
class App extends Component {
  render() {
    return (
      <div>
        <Router history={history}>
          <Navbar />
          <div
            style={{ position: "relative", height: "100%", overflow: "hidden" }}
          >
            <Switch>
              {/* {console.log(this.props.loaction)} */}
              <Route path="/" exact component={Home} />
              <Route path="/about" exact component={About} />
              <Route path="/team" exact component={Team} />
              <Route path="/ceo-message" exact component={Ceo} />
              <Route path="/projects-grid" exact component={ProjectsGrid} />
              <Route
                path="/projects-tile"
                render={() => {
                  if (window.innerWidth > 568) return <ProjectsTile />;
                  else return <ProjectsGrid />;
                }}
              />
              <Route path="/contactus" exact component={contact} />
              <Route path="/projects/:id" exact component={singleproject} />
              <Route path="/careers" exact component={careers} />
              <Route path="/news" exact component={news} />
              <Route path="/news/:id" exact component={article} />
              <Route path="/partners" exact component={partners} />
              <Route path="/sample" exact component={sample} />

              {/* <Route path="/pro" exact component={project} /> */}

              {/* {this.renderFooter(this.props.location.pathname)} */}
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default withRouter((props) => <App {...props} />);
