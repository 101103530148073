import React, { Component } from "react";

export class CheckBox extends Component {
  render() {
    return (
      <label className="checkBoxContainer">
        <input
          type="checkbox"
          id="checkAll"
          onClick={() => this.props.toggleType(this.props.name)}
        ></input>
        <span className="checkmark"></span>
        <span className="check-title">{this.props.name}</span>
      </label>
    );
  }
}

export default CheckBox;
