import React, { Component } from "react";
// import Logo from "../../assets/img/palm-logo/image-1@2x.png";
// import Video from "../../assets/img/palm constructions annual tracksound_1.mp4";
import Header from "./Header.jsx";
import About from "./About.jsx";
import Clients from "./Clients.jsx";
import Projects from "./Projects.jsx";
import News from "./News.jsx";
import { db } from "../../../src/firebase/firebaseConfig";

export default class Home extends Component {
  state = {
    clients: [],
    projects: [],
  };
  componentDidMount() {
    this.fetchClients();
    this.fetchProjects();
    // var video = document.getElementById("video_src");
    // video.pause();
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement
    ) {
      document.exitFullscreen();
    }
  }
  handleClients(clients) {
    // console.log(clients);
    let n;
    if (window.matchMedia("(min-width: 900px)").matches) {
      /* the view port is at least 900 pixels wide */
      n = 9;
    } else {
      /* the view port is less than 900 pixels wide */
      n = 4;
    } //tweak this to add more items per line
    const result = new Array(Math.ceil(clients.length / n))
      .fill()
      .map((_) => clients.reverse().splice(0, n));

    // console.log(clients);
    return result;
  }
  fetchClients() {
    db.collection("partners")
      .get()
      .then((snapshot) => {
        const clients = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.ref.id;
          clients.push(data);
        });
        const result = this.handleClients(clients);
        this.setState({ clients: result });
        // console.log(this.state.clients);
      });
  }
  fetchProjects() {
    db.collection("projects")
      .get()
      .then((snapshot) => {
        const projects = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.ref.id;

          projects.push(data);
        });
        this.setState({ projects: projects });
      });
  }
  navigate(id, projects, index) {
    this.props.history.push(`/projects/${id}`, {
      id,
      projects,
      index,
    });
  }

  importantProject(project) {
    let importantProjects = [
      "CIB Headquarters 3",
      "Hilton Plaza Hotel",
      "Gholion - Feed Mill Fish Planting Farm",
      "CIB Headquarters 4",
    ];
    return importantProjects.includes(project?.client);
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <main>
          <div className="home">
            <div className="section-video">
              <div className="video">
                <div className="video__content">
                  <video
                    id="video_src"
                    className="bg-video__content"
                    muted
                    loop
                    autoPlay
                  >
                    <source
                      src="https://res.cloudinary.com/dyckylmw3/video/upload/v1610606431/palm-construction/palm_constructions_annual_tracksound_1_kmche6.mp4"
                      type="video/mp4"
                    />
                    {/* <source src={Video} type="video/webm" /> */}
                    Your browser is not supported!
                  </video>
                </div>
                <div className="svg">
                  <div className="enable-sound" onClick={window.toggleMute}>
                    <span id="sound-anchor">Enable Sound</span>
                  </div>
                  <svg>
                    <circle className="anicircle" cx="65" cy="65" r="60" />
                  </svg>
                </div>
              </div>
            </div>
            <About />
            <Clients clients={this.state.clients.slice(0, 3)} />
            <Projects
              projects={this.state.projects.slice(0, 4)}
              navigate={() => this.navigate}
            />
            <News />
          </div>
        </main>
      </React.Fragment>
    );
  }
}
