import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class Footer extends Component {
  returnClass(paths) {
    const active = (path) => path === this.props.location.pathname;
    return paths.some(active) ? "footer__link active-link" : "footer__link";
  }
  render() {
    let props = this.props;
    return (
      <footer className="footer" style={props.background}>
        <div className="footer-overlay" style={props.background}></div>
        <div className="footer__header">
          <a href="/contactus">Get in touch</a>
        </div>
        <div className="footer__header-sub u-margin-bottom-medium">
          A question, a project? We’d love to hear from you…
        </div>
        <div className="footer__navigation u-margin-bottom-small">
          <ul className="footer__list">
            <li className="footer__item">
              <a href="/" className={this.returnClass(["/"])}>
                Home
              </a>
            </li>
            <li className="footer__item">
              <a
                href="about"
                className={this.returnClass([
                  "/about",
                  "/team",
                  "/ceo-message",
                ])}
              >
                About
              </a>
            </li>
            <li className="footer__item">
              <a
                href="/projects-tile"
                className={this.returnClass(["/projects-grid","/projects-tile"])}
              >
                Projects
              </a>
            </li>
            <li className="footer__item">
              <a href="/partners" className={this.returnClass(["/partners"])}>
                Partners
              </a>
            </li>
            {/* <li className="footer__item">
              <a href="#" className="footer__link">
                CSR
              </a>
            </li> */}
            <li className="footer__item">
              <a href="/news" className={this.returnClass(["/news"])}>
                News
              </a>
            </li>
            <li className="footer__item">
              <a href="/careers" className="footer__link">
                Careers
              </a>
            </li>
            <li className="footer__item">
              <a href="/" className="footer__link">
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>

        <div className="footer__copyright">Copyright © Palm Constructions</div>
      </footer>
    );
  }
}

export default withRouter(Footer);
