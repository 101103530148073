import React, { Component } from "react";
import { withRouter } from "react-router-dom";
var loadjs = require("loadjs");

export class sampleProject extends Component {
  state = {
    image: "",
  };
  componentDidMount() {
    loadjs("./js/owlCarousel.js", function () {});
    // console.log(this.props.images);
    if (this.props.images) {
      this.setState({ image: this.props.images });
    } else {
      this.setState({
        image:
          "https://previews.123rf.com/images/pavelstasevich/pavelstasevich1811/pavelstasevich181101027/112815900-no-image-available-icon-flat-vector.jpg",
      });
    }
    // if (this.props.images != undefined) {

    // }
  }
  navigate({ id, projects, index }) {
    console.log(id);
    this.props.history.push(`/projects/${id}`, {
      id,
      projects,
      index,
    });
  }
  render() {
    return (
      <React.Fragment>
        <div className="tile-div-data">
          <div className="type u-margin-bottom-small">{this.props.title}</div>
          <div className="name u-margin-bottom-medium">{this.props.type}</div>
          <button
            onClick={() => this.navigate(this.props)}
            className="viewProjectButton button button--isi button--isi--dirty-blue button--text-thick button--text-upper button--size-s"
          >
            <span>View Project</span>
          </button>
        </div>

        <div className="tile-div-img">
          <div className="tile-div-img-inner">
            <img alt="" className="inner-img-1" src={this.state.image}></img>
            <img alt="" className="inner-img-2" src={this.state.image}></img>
            <img alt="" className="inner-img-3" src={this.state.image}></img>
            <img alt="" className="inner-img-4" src={this.state.image}></img>
            <img alt="" className="inner-img-5" src={this.state.image}></img>
            <img alt="" className="inner-img-6" src={this.state.image}></img>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(sampleProject);
