import React, { Component } from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { db } from "../../../src/firebase/firebaseConfig";
import { withRouter } from "react-router-dom";
var loadjs = require("loadjs");

class News extends Component {
  state = {
    articles: [],
  };

  componentDidMount() {
    this.getArticles();
  }

  getArticles() {
    db.collection("articles")
      .get()
      .then((snapshot) => {
        const articles = [];
        snapshot.forEach((doc) => {
          const article_data = doc.data();
          article_data["id"] = doc.ref.id;

          articles.push(article_data);
        });
        this.setState({ articles });
        loadjs("./js/news-carousel.js", function () {});
      })
      .catch((error) => console.log(error));
  }
  Detailspage({ id }) {
    this.props.history.push(`/news/${id}`, {});
  }
  loadCarousel() {
    let { articles } = this.state;
    if (articles.length > 0) {
      return (
        <Carousel
          id="sampleSlide"
          indicators={false}
          interval={1500}
          className={`carousel slide show-neighbors`}
        >
          {articles.map((article, index) => (
            <Carousel.Item
              key={index}
              onClick={() => this.Detailspage(article)}
            >
              <div className="item__third">
                <Container className={[""]}>
                  <Row>
                    <Col md={12} sm={12} xs={12} className={["", "news-card"]}>
                      <div
                        className="news-card-image"
                        style={{
                          backgroundImage: `url(${article.image})`,
                        }}
                        onClick={this.sayHello}
                      ></div>
                      <div className="news-card-data">
                        <div className="title">
                          <h3>{article.title}</h3>
                        </div>
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{
                            __html: `${article.body}`,
                          }}
                        ></div>
                        <button className="button button--isi button--isi--white button--text-upper">
                          Know More
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      );
    }
  }
  render() {
    return (
      <React.Fragment>
        <section className="section-news">
          <div className="section-news-overlay"></div>
          <div className="see-more u-margin-bottom-small">
            <div className="container-fluid u-center-text">
              <h4
                data-aos="fade-up"
                className="see-more-header u-margin-bottom-medium"
              >
                Want To See More ?
              </h4>
              <Link
                data-aos="fade-up"
                to="/projects-grid"
                style={{ textDecoration: "none" }}
              >
                <button className="button button--isi button--isi--dirty-blue button--text-upper btn-global ">
                  <span>View All Work</span>
                </button>
              </Link>
              {/* <button className="button button--isi button--isi--dirty-blue button--text-thick button--text-upper button--size-s">
                <span>View All Work</span>
              </button> */}
            </div>
          </div>
          <section className="news">
            <div
              data-aos="fade-up"
              data-aos-delay="600"
              className="u-margin-bottom-big news-header"
            >
              <h2 className="heading-secondary">#NEWS</h2>
            </div>

            <div
              data-aos="fade-up"
              data-aos-delay="600"
              className="carousel-div"
            >
              {this.loadCarousel()}
              <a
                className="carousel-control-prev updated_carousel-control"
                href="#sampleSlide"
                role="button"
                data-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next updated_carousel-control"
                href="#sampleSlide"
                role="button"
                data-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </section>
          <Footer background={{ background: "transparent" }} />
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(News);
