import React, { Component } from "react";
import tick from "../../assets/img/news/tick@2x.png";
import AOS from "aos";
import { db } from "../../../src/firebase/firebaseConfig";
import Article from "./article";
import Footer from "../Footer";
var loadjs = require("loadjs");
export class news extends Component {
  state = {
    articles: [],
  };
  componentDidMount() {
    loadjs("./js/news.js", function () {});

    this.getArticles();
    AOS.init({ duration: 1500 });
  }

  Detailspage(id, author, image, articles, index) {
    this.props.history.push(`/news/${id}`, {
      id: id,
      author,
      image,
      articles,
      index,
    });
  }
  getArticles() {
    db.collection("articles")
      .get()
      .then((snapshot) => {
        const articles = [];
        snapshot.forEach((doc) => {
          const article_data = doc.data();
          article_data["id"] = doc.ref.id;

          articles.push(article_data);
        });
        this.setState({ articles: articles });
        console.log(this.state.articles);
        loadjs("./js/news.js", function () {});
      })
      .catch((error) => console.log(error));
  }

  render() {
    return (
      <div className="news-main">
        {/* <div
            id="filterButtonOpen"
            className="filter-button open-filter"
            onClick={() => window.toggleFilterWindow("Open")}
          ></div> */}
        <div
          id="filterBackground"
          className="filter-background"
          onClick={() => window.toggleFilterWindow("")}
        ></div>
        <div id="filterModal" className="filter-modal">
          <div className="filter-content">
            <div
              id="filterButtonClose"
              className="filter-button close-filter"
              onClick={() => window.toggleFilterWindow("Close")}
            ></div>
            <div className="filter-intro">
              <div className="filter-title">
                Sort By <span>&#9473;&#9473;</span>
              </div>
            </div>
            <div className="filter-options">
              <div className="option">
                <input type="checkbox"></input>
                <label>Latest Articles</label>
                <img alt="" src={tick}></img>
              </div>
              <div className="option">
                <input type="checkbox"></input>
                <label>Featured Articles</label>
                <img alt="" src={tick}></img>
              </div>
              <div id="sortDropdown" className="dropdown">
                <div id="selectdate" className="select-date">
                  <label>Date Published</label>
                  <div className="arrowdown"></div>
                </div>
                <div id="choiceholder" className="choice-holder">
                  <div className="choice">
                    <span>From</span>
                    <input type="date"></input>
                  </div>
                  <div className="choice">
                    <span>To</span>
                    <input type="date"></input>
                  </div>
                </div>
              </div>
            </div>
            <div class="buttons-div">
              <button className="button button--isi button--isi--dirty-blue button--text-thick button--text-upper button--size-s">
                Apply
              </button>
              <a>Reset</a>
            </div>
          </div>
        </div>

        <div className="news-header">
          <div className="news-header-overlay"></div>

          <div className="news-container">
            <div data-aos="fade-up" className="news-title">
              News
            </div>
            <div className="news-div">
              <div data-aos="fade-up" className="div-articles">
                {this.state.articles &&
                  this.state.articles.map((article, index) => {
                    return (
                      <Article
                        title={article.title}
                        author={article.author}
                        description={article.body}
                        key={article.id}
                        image={article.image}
                        id={article.id}
                        body={article.body}
                        articles={this.state.articles}
                        index={index}
                      ></Article>
                    );
                  })}

                <button className="button button--isi button--isi--white button--text-thick button--text-upper  button-load">
                  <div>Load more</div>
                </button>
              </div>
              <div className="div-posts">
                <div
                  className="recent-div"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="recent-title">Recent Posts</div>
                  <div className="posts-container">
                    {this.state.articles &&
                      this.state.articles.slice(0, 4).map((article, index) => {
                        return (
                          <div
                            onClick={() =>
                              this.Detailspage(
                                article.id,
                                article.author,
                                article.image,
                                this.state.articles,
                                index
                              )
                            }
                            className="single-post"
                          >
                            {article.title}
                          </div>
                        );
                      })}
                  </div>
                </div>
                {/* <div className="sort-div">
                  <div className="sort-title">Sort by</div>
                  <label className="checkBoxContainer">
                    <input type="checkbox"></input>
                    <span className="checkmark"></span>
                    <span className="check-title">Date Published</span>
                  </label>
                  <label className="checkBoxContainer">
                    <input type="checkbox"></input>
                    <span className="checkmark"></span>
                    <span className="check-title">Featured</span>
                  </label>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default news;
